<script>
</script>

<style>
	#header {
		height: 70px;
		display: flex;
		padding: 0 20px;
		align-items: center;
		background: #000;
    justify-content: space-between;
	}

	#logo {
		display: flex;
		align-items: center;
	}

	#logo {
		display: flex;
		align-items: center;
	}
	.logo_container {
		display: flex;
		align: center;
	}
	.logo_container img{
		width: 300px
	}
	.header-title {
		color: #fff;
		font-size: 20px;
	}
	#logo-text {
		font-size: 30px;
		color: var(--light-gray);
		font-family: 'A Love of Thunder';
		margin-right: 10px;
	}

	#svg-logo-tagline {
		font-size: 23px;
		fill: rgb(255, 255, 255);
		dominant-baseline: baseline;
		font-family: 'Neucha';
	}

	.icons {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.icon {
		width: 27px;
		height: 27px;
		margin-left: 15px;
	}

	.icon a{
		width: 100%;
		height: 100%;
	}

	.icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
	}
</style>

<div id="header">
  <div id="logo">
		<div class="logo_container" width="200px" height="70px">
			<img src="PUBLIC_URL/assets/img/shen-zhi-logo.jpg" alt="深圳职业技术大学"/>
		</div>
  </div>

	<div class="icons">
	  <div class="header-title">神经网络仿真学习</div>
	</div>
</div>